@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full;
  }
  body {
    @apply h-full;
  }
  #root {
    @apply h-full;
    background-color: theme("colors.gray.50");
  }
}

@layer components {
  .content-height {
    height: calc(100vh - 4rem); /* 4rem assuming the height of topbar is 64px */
    overflow-y: auto;
  }
  .content-height-max {
    max-height: calc(100vh - 4rem);
  }

  /* These classes are used in the admin prompt configurator */
  .content-height-reduced {
    height: calc(100% - 4rem); /* 4rem assuming the height of topbar is 64px */
    overflow-y: auto;
  }

  .content-height-reduced-max {
    height: calc(100% - 4rem); /* 4rem assuming the height of topbar is 64px */
  }

  .bounce-out-down {
    animation: bounce-out-down 2s ease infinite;
  }
  @keyframes bounce-out-down {
    0% {
      transform: translateY(0);
    }
    20% {
      opacity: 1;
      transform: translateY(-20px);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  .pulse-animation {
    animation: pulse-animation 1s infinite ease-in-out alternate;
  }
  @keyframes pulse-animation {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1.2);
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
